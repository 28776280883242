import { Link } from '@remix-run/react'
import { cn } from '#app/utils/misc.tsx'
import { type LinkIt, type LinkType } from '#types/graphql.js'

export default function ArrowLink({
	url,
	text,
	type,
	link,
	className,
}: {
	link?: LinkIt
	url: string
	text: string
	type: LinkType
	className?: string
}) {
	const uri =
		type === 'entry'
			? url?.replace('https://craft.settooncapital.com', '')
			: url
	return uri ? (
		<Link
			to={uri}
			className={cn([
				`group flex w-fit flex-row place-items-center space-x-4 pr-6 font-semibold leading-[26px] text-green-200 sm:px-0`,
				className,
			])}
		>
			<div>{text}</div>
			<div className="arrow relative">
				<img
					src="/assets/arrow.svg"
					alt="right arrow"
					className="h-[26px] w-[22px] transform object-right transition-all duration-300 ease-out group-hover:ml-5 group-hover:translate-x-[1px]"
				/>
			</div>
		</Link>
	) : null
}
